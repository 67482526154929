import { captureMessage, init, setContext } from '@sentry/gatsby';

import { isMonitoringEnabled } from 'utils/featureFlags';

export class SentryService {
  private static isInitialized = false;

  static init(): void {
    if (typeof window === 'undefined' || this.isInitialized) {
      return;
    }

    init({
      dsn: process.env.GATSBY_SENTRY_DSN,
      defaultIntegrations: false,
      integrations: [],
      tracesSampleRate: 0,
      replaysSessionSampleRate: 0,
      replaysOnErrorSampleRate: 0,
      autoSessionTracking: false,
      sendClientReports: false,
      attachStacktrace: true,
      enabled: isMonitoringEnabled,
    });

    setContext('device', {
      userAgent: navigator.userAgent,
      screen: {
        width: window.screen.width,
        height: window.screen.height,
      },
    });

    this.isInitialized = true;
  }

  static captureError({ context, message }: { context: any[]; message: string }): void {
    captureMessage(message, {
      level: 'error',
      extra: { context },
    });
  }
}
